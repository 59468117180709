import { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MapForRatesCharges from '../view/MapForRatesCharges';
import RatesChargesTable from "../view/RatesChargesTable";
import SecondSelectionTable from '../view/SecondSelectionTable';
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import yellowIcon from "../img/flight_yellow_24dp.svg"
import redIcon from "../img/flight_red_24dp.svg"
import blueIcon from "../img/flight_blue_24dp.svg"
import greyIcon from "../img/flight_grey_24dp.svg"
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingLeft: "1%",
      paddingRight: "1%",
    },
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      textAlign: "center",
      color: theme.palette.text.secondary,
      boxSizing: "border-box"
    },
    gridItem: {
      boxSizing: "border-box",
      padding: theme.spacing(1)
    },

  })
);

const flexContainer = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  justify: 'flex-end',
  alignItems: 'center',
};

const itemStyle = {
  height: "100%"
};

const RatesChargesPanel = (props) => {
  const classes = useStyles();
  const [comparedAirport, setComparedAirport] = useState(null);
  useEffect(() => {
    setComparedAirport(null);
  }, []);
  return (
    <Paper className={classes.root}>
      {
        <Grid container spacing={2} style={{ height: "780px" }}>
          <Grid item container lg={4} md={6} xs={12} spacing={2} className={classes.gridItem} style={{ height: "100%" }}>
            <Grid
              item
              lg={12}
              md={12}
              xs={6}
              //className={`${classes.masonryItemFirst}`}
              style={{ height: "50%" }}
            >
              <MapForRatesCharges selectedAirport={props.selectedAirport} comparedAirport={comparedAirport} setSelectedAirport={props.setSelectedAirport} setComparedAirport={setComparedAirport} data={props.data}></MapForRatesCharges>

            </Grid>
            <Grid style={{ height: "10%" }}>
              {props.selectedAirport && <List dense style={flexContainer}>
                <ListItem style={itemStyle}>
                  <img style={itemStyle} alt="" src={yellowIcon} />
                  <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Currently Selected Airport</Typography>} />
                </ListItem>
                <ListItem style={itemStyle}>
                  <img alt="" src={blueIcon + "#svgView(viewBox(7,-12,24,24))"} />
                  <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport with Information in Your Airport Region</Typography>} />
                </ListItem>
                <ListItem style={itemStyle}>
                  <img alt="" src={redIcon + "#svgView(viewBox(-15,5,24,24))"} />
                  <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport with Information in Compared Airport Region</Typography>} />
                </ListItem>

                <ListItem style={itemStyle}>
                  <img alt="" src={greyIcon + "#svgView(viewBox(7,-12,24,24))"} />
                  <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport without Information</Typography>} />
                </ListItem>
              </List>}
              {!props.selectedAirport && <List dense style={flexContainer}>

                <ListItem style={itemStyle}>
                  <img alt="" src={blueIcon + "#svgView(viewBox(7,-12,24,24))"} />
                  <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport with Information</Typography>} />
                </ListItem>


                <ListItem style={itemStyle}>
                  <img alt="" src={greyIcon + "#svgView(viewBox(7,-12,24,24))"} />
                  <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport without Information</Typography>} />
                </ListItem>
              </List>}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={6}
              //className={`${classes.masonryItemSecond}`}
              style={{ height: "40%" }}
            >


              <SecondSelectionTable selectedAirport={props.selectedAirport} comparedAirport={comparedAirport} allAirports={props.data} setComparedAirport={setComparedAirport}></SecondSelectionTable>
            </Grid>
          </Grid>
          <Grid item lg={8} md={6} xs={12} className={`${classes.gridItem}`} style={{ height: "100%" }}>
            <RatesChargesTable selectedAirport={props.selectedAirport} comparedAirport={comparedAirport} data={props.data} />

          </Grid>

        </Grid>}


    </Paper>
  );
};
export default RatesChargesPanel;
