import './styles/styles.scss';
import './helper/array';
import { AppBar, TextField } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Tabs, Hidden } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { React, useState, useEffect } from "react";
import PDFPanel from './view/PDFPanel';
import IntermodalPanel from './view/IntermodalPanel';
import UserGuidePanel from './view/UserGuidePanel';
import Survey from './data/survey_results.json';
import AirportCoords from './data/texas-airports-coordinates.json';
import RatesChargesPanel from './view/RatesChargesPanel';
import BannerBackground from './img/banner-6.png';
import StartupDialogs from './view/StartupDialogs';
function App() {

  const [allAirports, setAllAirports] = useState(null);

  const [selectedAirport, setSelectedAirport] = useState(null);

  const [currentTab, setCurrentTab] = useState('0');

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      alignItems: 'center',
      padding: theme.spacing(0),
      fontSize: '1.5rem',
      fontFamily: 'fantasy',
      fontWeight: 'border',
      // float: 'left'
    },
    banner: {
      float: 'right',
      height: '92px'

    },
    brand: {
      height: '100%'
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'white',
      borderColor: 'white !important'
    },
    cssLabel: {
      color: 'white',
    },
    cssOutlined: {
      color: 'white',
      '&$cssFocused': {
        color: 'white'
      }
    },

    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: 'white !important',
      },
      color: 'white'
    },

    cssFocused: {
      color: 'white'
    },

    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'white !important'
    },
    toolBar: {
      width: '100%',
    },

  }));



  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  /*
   * On the change here, the items need to filter to only show items on map that match the filter.
   */
  const handleCoordChange = (e, newValue) => {
    if (newValue !== null && typeof (newValue) !== "undefined") {
      setSelectedAirport(newValue);
    }

  };


  const classes = useStyles();

  const [filteredResults, setFilteredResults] = useState(null);
  // const [filters, setFilters] = useState({ Region: { "East Texas": true, "Panhandle": true, "West Texas": true, "North Texas": true, "South Texas": true, "Central Texas": true, "Upper Gulf Coast": true }, totalArea: { min: 0, max: 10000 }, developmentAcres: { min: 0, max: 10000 }, assetType: ["Regional", "Basic", "Local", "Other"] });

  useEffect(() => {
    //Load data at the startup
    let combined = [...Survey];
    combined.forEach((airport) => {
      let info = AirportCoords.hasItem("Airport", airport.Airport);
      if (info.length > 0) { airport.X = info[0].X; airport.Y = info[0].Y; }
    });

    setFilteredResults(combined);
    setAllAirports(combined);
    setSelectedAirport(null);

  }, []);

  const styles = {
    banner: {
      backgroundImage: `url(${BannerBackground})`
    }
  }
  return (
    <div className="App">
      <div className={classes.root}>
        <StartupDialogs />
        <AppBar position="static" >
          <Grid container item lg={12} direction={"row"} >
            <Grid container item lg={4} alignItems="center">

              <Typography className={classes.title} >
                Airport Intermodal Connectivity and Self-Sufficiency Model
              </Typography>


            </Grid>
            <Grid item lg={8} align="center">
              <Hidden xsDown mdDown><img src={BannerBackground} className={classes.banner}></img></Hidden>
            </Grid>
          </Grid>

        </AppBar>
      </div>

      {filteredResults && <TabContext value={currentTab}>
        <AppBar position="static">
          <Grid>
            <Grid container justifyContent="flex-start">


              <Toolbar className={classes.toolBar}> {<Autocomplete
                onChange={handleCoordChange}
                /// Options have to be sorted by the item for grouping first to group them correctly
                value={selectedAirport}
                id="search-selected"
                size="small"
                options={allAirports.sort((a, b) => -b.Region.localeCompare(a.Region))}
                groupBy={(option) => option.Region}
                getOptionLabel={(option) => option.Airport}
                style={{ minWidth: 300, height: 40, color: "white" }}
                renderInput={(params) =>
                  <TextField     {...params}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      classes: {
                        root: classes.cssOutlined,
                        focused: classes.cssFocused,
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      }

                    }}

                    label="Select Your Airport" variant="outlined" />}
              />}

                <Tabs aria-label="simple tabs example" indicatorColor="primary" style={{ minWidth: 600 }}
                  onChange={handleChange} value={currentTab} TabIndicatorProps={{ style: { background: 'gold' } }}>
                  <Tab label="Rates and Charges" value={"0"} />
                  <Tab label="Economic Development & Connectivity" value={"1"} />

                </Tabs>
                <Grid item xs={6} spacing={2} container justifyContent="flex-end" alignItems="center" direction="row">
                  <Grid item><UserGuidePanel /></Grid>
                  <Grid item><PDFPanel data={filteredResults.removeEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"])} /></Grid>
                </Grid>
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>
        <TabPanel value={"0"}>
          <RatesChargesPanel selectedAirport={selectedAirport} setSelectedAirport={setSelectedAirport} data={allAirports} />
        </TabPanel>
        <TabPanel value={"1"}  >
          <IntermodalPanel selectedAirport={selectedAirport} setSelectedAirport={setSelectedAirport} data={allAirports} />
        </TabPanel>

      </TabContext>}

    </div>
  );
}

export default App;
