import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import TTIIcon from '../img/TTI-Color.png';
import TxDOTIcon from '../img/2012-TxDOT-334px-wide.png';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuDialogContent-root': {
        padding: 6,
    },
    '& .MuDialogActions-root': {
        padding: 6,
    },
    '& .MuiDialog-paperWidthSm': {
        maxWidth: 1200,
        opacity: 0.85,
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{ float: 'right' }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function StarupDialogs() {
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Airport Intermodal Connectivity and Self-Sufficiency Model
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        The Airport Intermodal Connectivity and Self-Sufficiency Model is designed to provide airport managers, planners, local officials with a tool to understand the value of their assets and operations relative to airports in their region and across the state of Texas. The tool also provides airport managers, local officials, and developers with an understanding of the available infrastructure connections to airports as well as their development potential through the Economic Development database. The User can search for information on their own airport or airports that fit their criteria. Overall, the tool provides a baseline for ensuring financial self-sufficiency as well as showing future development potential.
                    </Typography>
                    <Typography gutterBottom>
                        This project was sponsored by TxDOT’s Research and Technology Implementation Division. The model was developed by Texas A&M Transportation Institute, Infrastructure Investment Analysis Program.
                    </Typography>
                    <Typography gutterBottom>
                        For more information please contact:
                    </Typography>

                    <Grid container direction='row' justifyContent="center"
                        alignItems="center">
                        <Grid item>
                            <img
                                src={TxDOTIcon}
                                //srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={'TXDOT'}
                                loading="lazy"
                                style={{ width: 'auto', height: 100 }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>
                                <p>
                                    <strong>Jim Halley, A.A.E</strong>
                                    <br />
                                    Director, Planning & Programming
                                    <br />
                                    TxDOT Aviation Division
                                    <br />
                                    (512) 416-4525
                                    <br />
                                    <a href="mailto:James.Halley@txdot.gov">
                                        James.Halley@txdot.gov
                                    </a>
                                </p>
                            </Typography></Grid>
                        <Grid item>
                            <img
                                src={TTIIcon}
                                //srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={'TTI'}
                                loading="lazy"
                                style={{ width: 'auto', height: 60 }}
                            />
                        </Grid>
                        <Grid item>  <Typography>
                            <p>
                                <strong>Jeff Borowiec, Ph.D.</strong>
                                <br />
                                Senior Research Scientist
                                <br />
                                Texas A&M Transportation Institute
                                <br />
                                (979) 317-2283
                                <br />
                                <a href="mailto:jborowiec@tamu.edu">jborowiec@tamu.edu</a>
                            </p>
                        </Typography></Grid>
                    </Grid>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div>
    );
}