import Helper from '../helper/helper';
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, LayersControl, FeatureGroup, useMap, LayerGroup } from 'react-leaflet';
//import Legend from "./Legend";
import Leaflet from "leaflet";
//import L from "leaflet";
//import shadowIcon from "./img/flight_shadow_24dp.svg"
import yellowIcon from "../img/flight_yellow_24dp.svg"
import blueIcon from "../img/flight_blue_24dp.svg"
import redIcon from "../img/flight_red_24dp.svg"
import greyIcon from "../img/flight_grey_24dp.svg"
import iconShadow from "leaflet/dist/images/marker-shadow.png";
//import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
//import { blue } from '@material-ui/core/colors';
import { React, useState, useEffect, useRef } from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingLeft: "1%",
            paddingRight: "1%",
        },
        paper: {
            padding: theme.spacing(2),
            height: "100%",
            textAlign: "center",
            color: theme.palette.text.secondary,
            boxSizing: "border-box"
        },
        gridItem: {
            boxSizing: "border-box",
            padding: theme.spacing(1)
        },
        popUp: {
            width: "350px",
            opacity: 0.9,
            overflowWrap: "break-word",

        }

    })
);
const MapForRatesCharges = (props) => {

    const classes = useStyles();
    const selectedAirport = props.selectedAirport;
    const comparedAirport = props.comparedAirport;
    const data = props.data;
    const { BaseLayer } = LayersControl;
    const BlueIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: blueIcon,
        iconRetinaUrl: blueIcon,
        shadowUrl: iconShadow
    });

    const YellowIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: yellowIcon,
        // iconUrl: yellowIcon,
        iconRetinaUrl: yellowIcon,
        shadowUrl: yellowIcon,
        iconSize: [41, 41]
    });

    const RedIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: redIcon,
        iconRetinaUrl: redIcon,
        shadowUrl: iconShadow
    });

    const GreyIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: greyIcon,
        iconRetinaUrl: greyIcon,
        shadowUrl: iconShadow,
    });
    function SetViewOnClick({ coords }) {

        const mapCoords = useMap();
        mapCoords.setView(coords, mapCoords.getZoom());

        return null;
    };
    function setSelectedOnClick(name) {
        props.setSelectedAirport(data.hasItem("Airport", name)[0]);
    }

    function setComparedOnClick(name) {
        props.setComparedAirport(data.hasItem("Airport", name)[0]);
    }

    const [map, setMap] = useState(null);

    return (
        <MapContainer zoom={5} scrollWheelZoom={true} style={{ height: "100%" }} whenCreated={setMap}>
            {/* <Legend map={map}/> */}

            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <SetViewOnClick coords={[selectedAirport ? selectedAirport.Y : 31.66593547, selectedAirport ? selectedAirport.X : -99.1486478]} />
            <FeatureGroup>
                {!selectedAirport && data.removeEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).map((airport, idx) => {


                    return (
                        <Marker
                            key={"rates-charges-marker-has-data-all-" + idx}
                            position={[airport.Y, airport.X]}
                            icon={BlueIcon}

                        >
                            <Popup>
                                <div>
                                    <li>Airport Name: {airport.Airport}</li>
                                    <li>Region: {airport.Region}</li>
                                    <li>County: {airport.County}</li>
                                    <li>Asset Category: {airport["Asset Category"]}</li>
                                    {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}

                                </div>
                                <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                    <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                    <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button>
                                </Grid>
                            </Popup>
                        </Marker>
                    );
                })
                }

                {!selectedAirport && data.selectEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).map((airport, idx) => {

                    return (
                        <Marker
                            key={"rates-charges-marker-empty-all-" + idx}
                            position={[airport.Y, airport.X]}
                            icon={GreyIcon}
                        >
                            <Popup className={classes.popUp}>
                                {/* <div>
                    {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}
                  </div> */}
                                <div><li>Airport: {airport.Airport}</li>No data is provided</div>
                                <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                    <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                    <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button>
                                </Grid>
                            </Popup>
                        </Marker>
                    );
                })
                }
                {selectedAirport && data.removeEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).hasItem("Region", selectedAirport.Region).map((airport, idx) => {


                    if (airport.Airport !== selectedAirport.Airport && airport.Airport !== (comparedAirport && comparedAirport.Airport))
                        return (
                            <Marker
                                key={"rates-charges-marker-has-data-selected-" + idx}
                                position={[airport.Y, airport.X]}
                                icon={BlueIcon}
                                zIndexOffset={95}
                            >
                                <Popup>
                                    <div>
                                        <li>Airport Name: {airport.Airport}</li>
                                        <li>Region: {airport.Region}</li>
                                        <li>County: {airport.County}</li>
                                        <li>Asset Category: {airport["Asset Category"]}</li>
                                        {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}

                                    </div>
                                    <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                        <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button>
                                    </Grid>
                                </Popup>
                            </Marker>
                        );
                })
                }

                {selectedAirport && data.selectEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).hasItem("Region", selectedAirport.Region).map((airport, idx) => {
                    if (airport.Airport !== selectedAirport.Airport && airport.Airport !== (comparedAirport && comparedAirport.Airport))
                        return (
                            <Marker
                                key={"rates-charges-marker-empty-selected-" + idx}
                                position={[airport.Y, airport.X]}
                                icon={GreyIcon}
                            >
                                <Popup className={classes.popUp}>
                                    {/* <div>
                    {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}
                  </div> */}
                                    <div><li>Airport: {airport.Airport}</li>No data is provided</div>
                                    <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                        <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button>
                                    </Grid>
                                </Popup>
                            </Marker>
                        );
                })
                }

                {comparedAirport && data.removeEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).hasItem("Region", comparedAirport.Region).map((airport, idx) => {


                    if (airport.Airport !== (comparedAirport && comparedAirport.Airport) && airport.Airport !== (selectedAirport && selectedAirport.Airport))
                        return (
                            <Marker
                                key={"rates-charges-has-data-compared-" + idx}
                                position={[airport.Y, airport.X]}
                                icon={RedIcon}
                                zIndexOffset={90}
                            >
                                <Popup className={classes.popUp}>
                                    <div>
                                        <li>Airport Name: {airport.Airport}</li>
                                        <li>Region: {airport.Region}</li>
                                        <li>County: {airport.County}</li>
                                        <li>Asset Category: {airport["Asset Category"]}</li>
                                        {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}
                                    </div>
                                    <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                        <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button>
                                    </Grid>
                                </Popup>
                            </Marker>
                        );
                })
                }

                {comparedAirport && data.selectEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).hasItem("Region", comparedAirport.Region).map((airport, idx) => {
                    if (airport.Airport !== comparedAirport.Airport && airport.Airport !== (selectedAirport && selectedAirport.Airport))
                        return (
                            <Marker
                                key={"rates-charges-marker-has-data-compared-" + idx}
                                position={[airport.Y, airport.X]}
                                icon={GreyIcon}
                            >
                                <Popup>
                                    {/* <div>
{Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}
</div> */}
                                    <div>
                                        <li>Airport Name: {airport.Airport}</li>
                                        <li>Region: {airport.Region}</li>
                                        <li>County: {airport.County}</li>
                                        No data is provided
                                    </div>
                                    <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                        <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button>
                                    </Grid>
                                </Popup>
                            </Marker>
                        );
                })
                }
                {selectedAirport &&
                    <Marker key={"rates-charges-marker-selected"} position={[selectedAirport.Y, selectedAirport.X]}
                        icon={YellowIcon} zIndexOffset={100}>
                        <Popup>
                            <ul>
                                <li>Airport Name: {selectedAirport.Airport}</li>
                                <li>Region: {selectedAirport.Region}</li>
                                <li>County: {selectedAirport.County}</li>
                                <li>Asset Category: {selectedAirport["Asset Category"]}</li>
                                {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", selectedAirport[item.id])}</li>))}
                            </ul>
                        </Popup>
                    </Marker>

                }

                {comparedAirport && <Marker key={"rates-charges-marker-compared"} position={[comparedAirport.Y, comparedAirport.X]}
                    icon={YellowIcon} zIndexOffset={100}>
                    <Popup>
                        <li>Airport Name: {comparedAirport.Airport}</li>
                        <li>Region: {comparedAirport.Region}</li>
                        <li>County: {comparedAirport.County}</li>
                        <li>Asset Category: {comparedAirport["Asset Category"]}</li>
                        <div>
                            {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", comparedAirport[item.id])}</li>))}
                        </div>

                    </Popup>
                </Marker>}
            </FeatureGroup>
        </MapContainer>
    );
}

export default MapForRatesCharges;
