import Helper from '../helper/helper';
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, LayersControl, FeatureGroup, useMap } from 'react-leaflet';
import Leaflet from "leaflet";
import yellowIcon from "../img/flight_yellow_24dp.svg"
import blueIcon from "../img/flight_blue_24dp.svg"
import greyIcon from "../img/flight_grey_24dp.svg"
import iconShadow from "leaflet/dist/images/marker-shadow.png";
// import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
// import { blue } from '@material-ui/core/colors';
import { React, useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";

const MapForIntermodal = (props) => {
    const selectedAirport = props.selectedAirport;
    const data = props.data;
    const BlueIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: blueIcon,
        iconRetinaUrl: blueIcon,
        shadowUrl: iconShadow
    });

    const YellowIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: yellowIcon,
        // iconUrl: yellowIcon,
        iconRetinaUrl: yellowIcon,
        shadowUrl: yellowIcon,
        iconSize: [41, 41]
    });

    const GreyIcon = Leaflet.icon({
        ...Leaflet.Icon.Default.prototype.options,
        iconUrl: greyIcon,
        iconRetinaUrl: greyIcon,
        shadowUrl: iconShadow
    });
    function SetViewOnClick({ coords }) {

        const mapCoords = useMap();
        mapCoords.setView(coords, mapCoords.getZoom());

        return null;
    }
    const [icon, setIcon] = useState(BlueIcon);
    const changeIconColor = (icon) => {
        if (icon.options.iconUrl === BlueIcon.options.iconUrl) {
            setIcon((current) => (current = YellowIcon))

        } else {
            setIcon((current) => (current = BlueIcon))
        }
    }

    function setSelectedOnClick(name) {
        props.setSelectedAirport(data.hasItem("Airport", name)[0]);
    }
    return (

        <MapContainer zoom={5} scrollWheelZoom={true} style={{ height: "100%" }}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <SetViewOnClick coords={[selectedAirport ? selectedAirport.Y : 31.66593547, selectedAirport ? selectedAirport.X : -99.1486478]} />

            <FeatureGroup>

                {data.removeEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).map((airport, idx) => {

                    // if (airport.X === undefined || airport.Y === undefined) console.log("Airport with no latlng info: ", airport);
                    if (airport.Airport !== (selectedAirport && selectedAirport.Airport))
                        return (
                            <Marker
                                key={"has-data" + idx}
                                position={[airport.Y, airport.X]}
                                icon={BlueIcon}
                                eventHandlers={{ click: (e) => changeIconColor(icon) }}
                            >
                                <Popup>
                                    <div>
                                        <li>Airport Name: {airport.Airport}</li>
                                        <li>Region: {airport.Region}</li>
                                        <li>County: {airport.County}</li>
                                        <li>Asset Category: {airport["Asset Category"]}</li>
                                        {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}
                                    </div>
                                    <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                        {/* <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button> */}
                                    </Grid>
                                </Popup>
                            </Marker>
                        );
                })
                }

                {data.selectEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"]).map((airport, idx) => {
                    // if (airport.Airport !== (selectedAirport && selectedAirport.Airport))
                    //     if (airport.X === undefined || airport.Y === undefined) console.log("Airport with no latlng info: ", airport);
                    return (
                        <Marker
                            key={"empty" + idx}
                            position={[airport.Y, airport.X]}
                            icon={GreyIcon}
                        >
                            <Popup>
                                {/* <div>
                    {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", airport[item.id])}</li>))}
                  </div> */}
                                <div>
                                    <li>Airport: {airport.Airport}</li>
                                    <li>Region: {airport.Region}</li>
                                    <li>County: {airport.County}</li>
                                    No data is provided
                                </div>
                                <Grid style={{ paddingTop: "5px", width: "300px" }}>
                                    <Button size="small" variant="outlined" color="primary" onClick={() => { setSelectedOnClick(airport.Airport) }}>Your airport</Button>
                                    {/* <Button style={{ float: "right" }} size="small" variant="outlined" color="primary" onClick={() => { setComparedOnClick(airport.Airport) }}>Comparison airport</Button> */}
                                </Grid>
                            </Popup>
                        </Marker>
                    );
                })
                }
                {selectedAirport && <Marker key={"intermodal-marker-selected"} position={[selectedAirport.Y, selectedAirport.X]}
                    icon={YellowIcon} zIndexOffset={100}>
                    <Popup>
                        <div>
                            <li>Airport Name: {selectedAirport.Airport}</li>
                            <li>Region: {selectedAirport.Region}</li>
                            <li>County: {selectedAirport.County}</li>
                            <li>Asset Category: {selectedAirport["Asset Category"]}</li>
                            {Helper.getPopupItems().map((item) => (<li>{item.label.concat(": ", selectedAirport[item.id])}</li>))}
                        </div>
                    </Popup>
                </Marker>}

            </FeatureGroup>
        </MapContainer>
    );
}

export default MapForIntermodal;
