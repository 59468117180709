import { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { Button } from "@material-ui/core";
import Dictionary from "../data/survey_dictionary.json";
import "../helper/array.js";
import Helper from "../helper/helper.js"
import DescriptionIcon from '@material-ui/icons/Description';
import UsersGuide from '../document/UsersGuide.pdf'

const UserGuidePanel = (props) => {

	const getUserGuide = () => {
		
		window.open(UsersGuide,"_blank");

	}
	return (
		<Button id="get-usersguide" variant="contained" color="primary" onClick={getUserGuide} startIcon={<DescriptionIcon />}>User's Guide</Button>
		
	);
}
export default UserGuidePanel;
