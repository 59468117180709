import { React, useState, useEffect, Fragment } from "react";
import { Typography } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Helper from '../helper/helper';
import { makeStyles } from '@material-ui/core/styles';
import Description from '../data/category_description.json'
const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	headerName: {
		width: '150px',
	},
	headerCategory: {
		width: '100px',
	},
	headerIcon: {
		width: '70px',
		padding: '0 0 0 0'

	},
	headerWrap: {
		border: 'none',
		textAlign: 'center',
	},
	headerState: {
		minWidth: 60,
	},
	headerReliever: {
		minWidth: 60,
	},
	tableRow: {
		"&>:nth-child(6)": {
			borderRight: '1px solid lightgrey',

			// backgroundColor: 'red'

		},
		"&>:nth-child(9)": {
			borderRight: '1px solid lightgrey',

			// backgroundColor: 'red'

		},
	},
	tableHeader: {
		"&>:nth-child(6)": {
			borderRight: '1px solid grey',

			// backgroundColor: 'red'

		},
		"&>:nth-child(7)": {
			borderRight: '1px solid grey',

			// backgroundColor: 'red'

		},
	}
});


const RatesChargesTable = (props) => {

	const classes = useRowStyles();
	const selectedAirport = props.selectedAirport;
	const comparedAirport = props.comparedAirport;
	const items = Helper.getRatesAndChargesItems();
	const categories = items.findUnique("category");


	function Row(props) {
		const { rows, category, level, children, maxlevel } = props;
		const [open, setOpen] = useState(false);
		const classes = useRowStyles();
		const levels = level ? Array.from(Array(level).keys()) : new Array(0);//Requires ES6 polyfill to run
		const levels2 = level ? Array.from(Array(level + 1).keys()) : new Array(0);//Requires ES6 polyfill to run
		const levelsmax = maxlevel ? Array.from(Array(maxlevel - level).keys()) : new Array(0);//Requires ES6 polyfill to run
		return (<Fragment>
			<TableRow key={category}>
				{levels.map((e) => { return <TableCell colSpan={1} />; })}
				<TableCell className={level === 1 ? classes.headerIcon : ""}>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>


				{levelsmax.map((e) => <TableCell colSpan={1} />)}
				<TableCell>{category}</TableCell>
				<TableCell colSpan={rows[0].length - 1 + level}>
					<Typography variant="body2" color="textSecondary" component="p">
						{Description.hasItem("name", category)[0] ? Description.hasItem("name", category)[0].description : ""}
					</Typography>
				</TableCell>

			</TableRow>

			{children ? (open && children) : (open && rows.map((row, id) => (
				<TableRow className={classes.tableRow} key={category + level}>
					{levels2.map((e) => <TableCell colSpan={1} />)}
					{levelsmax.map((e) => <TableCell colSpan={1} />)}
					<TableCell></TableCell>
					{row.map((e) => <TableCell colSpan={1}>{e}</TableCell>)}
				</TableRow>
			)))}

		</Fragment>);
	}

	const RowSet = (props) => {
		//data: The set of data to show
		//categories: Categories array. Each element indicate the category name to collapse.
		//cols: ids in the data record indicate the category
		//children: 
		const { data, categories, col, group, level, maxlevel } = props;
		const isEmpty = (arr, cat) => {
			// console.log("arr in isEmpty() in RowSet", arr);
			// console.log("cat in isEmpty() in RowSet", cat);
			if (arr.hasItem("category", cat).findUnique("subcategory").length > 1) return false;
			else return true;
		}
		const classes = useRowStyles();
		return (<Fragment>

			{categories.map((cat) => <Row rows={createRowsBy({ data, category: { name: col, value: cat }, group, selectedAirport, comparedAirport })} category={cat} level={level} maxlevel={1}
				children={maxlevel - level === 0 || isEmpty(Helper.getRatesAndChargesItems(), cat) ?
					null : <RowSet categories={Helper.getRatesAndChargesItems().hasItem(col, cat).findUnique("subcategory")}
						data={data}
						col={"subcategory"}
						group={["label"]}
						level={level + 1} />} />)}
		</Fragment>

		);
	};



	const createRowsBy = ({ data, category, selectedAirport, comparedAirport, group }) => {

		let rows = Helper.getRatesAndChargesItems()
			.hasItem(category.name, category.value)
			.map((item) => {
				// console.log("Item in createRowsBy():", item);
				let arr = group.map((e) => item[e]);
				// console.log("arr in createRowsBy():", arr);
				// console.log("selectedAirport in createRowsBy():", selectedAirport);
				// console.log("comparedAirport in createRowsBy():", comparedAirport);
				return arr.concat([
					Helper.getSummary(item, data),
					Helper.getSummary(item, data.hasItem("Reliever", 'Y')),
					Helper.getSummary(item, data.hasItem("Airport", selectedAirport.Airport)),
					//selectedAirport[item.id],
					Helper.getSummary(item, data.hasItem("Region", selectedAirport.Region)),
					Helper.getSummary(item, data.hasItem("Asset Category", selectedAirport["Asset Category"])),
					comparedAirport && Helper.getSummary(item, data.hasItem("Airport", comparedAirport.Airport)),
					// comparedAirport[item.id],
					comparedAirport && Helper.getSummary(item, data.hasItem("Region", comparedAirport.Region))
				]);
			});
		return rows;
	};

	return (
		<Paper style={{ height: "98%" }}>
			{selectedAirport && <TableContainer style={{ height: "100%" }}>
				<Table stickyHeader size="small" aria-label="a dense table">
					<TableHead>
						{/* <TableRow>							
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell colSpan={3}>{selectedAirport.Airport}</TableCell>
							<TableCell colSpan={2}> {comparedAirport && comparedAirport.Airport}</TableCell>
						</TableRow> */}
						<TableRow>
							<TableCell className={classes.headerIcon}></TableCell>
							<TableCell className={classes.headerIcon}></TableCell>

							<TableCell className={classes.headerCategory}></TableCell>

							<TableCell className={classes.headerName}>Name</TableCell>

							<TableCell className={classes.headerState}>State</TableCell>

							<TableCell className={classes.headerReliever}>Reliever</TableCell>
							<TableCell colSpan={3}>
								<TableRow ><TableCell className={classes.headerWrap} colSpan={3}>{selectedAirport && selectedAirport.Airport}</TableCell></TableRow>
								<TableRow className={classes.headerWrap} >
									<TableCell className={classes.headerWrap}>Airport</TableCell>
									<TableCell className={classes.headerWrap}>Region</TableCell>
									<TableCell className={classes.headerWrap}>Asset Category</TableCell>
								</TableRow>
							</TableCell>


							<TableCell colSpan={2}>
								<TableRow><TableCell className={classes.headerWrap} colSpan={3}>{comparedAirport && comparedAirport.Airport}</TableCell></TableRow>
								<TableRow>
									<TableCell className={classes.headerWrap}>{comparedAirport && "Airport"}</TableCell>
									<TableCell className={classes.headerWrap}>{comparedAirport && "Region"}</TableCell>
								</TableRow>
							</TableCell>



						</TableRow>


					</TableHead>
					<TableBody>
						{/* {categories.map((cat) =>
							<Row rows={createRowsBy({ data: props.data, category: {name:"category",value:cat}, selectedAirport, comparedAirport,group:["subcategory","label"] })} category={cat} data={props.data}></Row>
						)} */}
						<RowSet categories={categories} data={props.data} col={"category"} group={["subcategory", "label"]} level={0} maxlevel={1}></RowSet>
					</TableBody>
					{/* {
						categories.map((e) => (<TableBody>
							<TableRow style={{ fontWeight: "bold" }}>
								{e}
							</TableRow>
							
							{items.hasItem("category", e).map((item) => (
								<TableRow>

									<TableCell>
										{item.subcategory}
									</TableCell>
									<TableCell>
										{item.label}
									</TableCell>
									<TableCell>
										{
											Helper.getSummary(item, props.data)
										}
									</TableCell>
									<TableCell>
										{
											selectedAirport[item.id]
										}
									</TableCell>
									<TableCell>
										{
											Helper.getSummary(item, props.data.hasItem("Region", selectedAirport.Region))
										}
									</TableCell>
									<TableCell>
										{
											Helper.getSummary(item, props.data.hasItem("Asset Type",selectedAirport["Asset Type"]))
										}
									</TableCell>
								
									<TableCell>
										{
											comparedAirport[item.id]
										}
									</TableCell>
									<TableCell>
										{
											Helper.getSummary(item, props.data.hasItem("Region", comparedAirport.Region))
										}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						)
						)} */}
				</Table>
			</TableContainer>}
			{!selectedAirport && <Typography>Please select your airport.</Typography>}
		</Paper>

	)
}
export default RatesChargesTable
