export const isObject = (x) => {
    return x !== null || (x && x.toString() === "[object Object]");
}

export const isSubset = (superObj, subObj) => {
    return Object.keys(subObj).every(ele => {
        // if (typeof subObj[ele] == 'object') {
        //     return isSubset(superObj[ele], subObj[ele]);
        // }
        // return subObj[ele] === superObj[ele];
        return JSON.stringfy(subObj[ele]) === JSON.stringify(superObj[ele]);
    });
};


export const initializeReducer = (items) => {
    let rtn = { average: {}, count: {} };
    items.forEach(e => { rtn.average[e.id] = 0; rtn.count[e.id] = 0 });
    return rtn;
};

const averageNumber = (prev = { average: {}, count: {} }, cur) => {
    //function used in the reducer for calculation the average.    
    let rtn = { ...prev };
    const property_list = Object.keys(rtn.average);
    property_list.forEach((property) => {
        if (cur[property] !== null) {
            rtn.average[property] = (prev.count[property] * prev.average[property] + cur[property]) / (prev.count[property] + 1);
            rtn.count[property] = prev.count[property] + 1;
        }
    });
    return rtn;
}

const averageNumberByName = (prev = { average: 0, count: 0 }, cur, name) => {
    let rtn = { ...prev };
    if (cur[name] !== null && cur[name] !== "") {
        rtn.average = (prev.count * prev.average + cur[name]) / (prev.count + 1);
        rtn.count = prev.count + 1;
    }
    return rtn;
};

if (!Array.prototype.hasOwnProperty("hasItem")) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(
        Array.prototype,
        'hasItem',
        {
            //It is very important that <function> has to be used here to access this.
            //()=>{} does not work!!!!!!!!!
            value: function (name, value) {
                return this.filter(e => {
                    if (Array.isArray(value)) return value.includes(e[name]);
                    else return e[name] === value;
                });
            }
        }
    );
}



if (!Array.prototype.hasOwnProperty("withinRange")) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(
        Array.prototype,
        'withinRange',
        {
            value: function (name, min, max) {
                let results = this;
                if (min !== null && min !== "") results = this.filter(e => e[name] === null || e[name] === "" || e[name] >= min);
                if (max !== null && max !== "") results = this.filter(e => e[name] === null || e[name] === "" || e[name] <= max);
                if (min !== null && min !== "" && max !== null && max !== "") results = results.filter(e => e[name] !== null && e[name] !== "" && e[name] >= min && e[name] <= max);
                return results;
            }
        }
    );

}

if (!Array.prototype.hasOwnProperty("removeEmptyItems")) {
    Object.defineProperty(
        Array.prototype,
        'removeEmptyItems',
        {
            value: function (excluded_list) {
                return this.filter((record) => {
                    let isNull = true;
                    Object.keys(record).forEach((key) => {

                        if (!excluded_list.includes(key) && record[key] !== null && record[key] !== "") isNull = false;
                    });
                    return !isNull;
                });
            }
        }
    );
}

if (!Array.prototype.hasOwnProperty("selectEmptyItems")) {
    Object.defineProperty(
        Array.prototype,
        'selectEmptyItems',
        {
            value: function (excluded_list) {
                return this.filter((record) => {
                    let isNull = true;
                    Object.keys(record).forEach((key) => {
                        if (!excluded_list.includes(key) && record[key] !== null && record[key] !== "") isNull = false;
                    });
                    return isNull;
                });
            }
        }
    );
}

if (!Array.prototype.hasOwnProperty("average")) {
    Object.defineProperty(
        Array.prototype,
        'average',
        {
            value: function (items) {
                //items: Input array, which contains the information of each item to be aggregated. in the form of [{...id:"1"}]                
                //return: object with the average values of the array for input items
                //console.log("average function called");
                return this.reduce((acc, cur) => { return averageNumber(acc, cur); }, initializeReducer(items));
            }
        }
    );

}

if (!Array.prototype.hasOwnProperty("averageByName")) {
    Object.defineProperty(
        Array.prototype,
        'averageByName',
        {
            value: function (name) {
                //name: name for the property to be aggregated.
                //return: {average: ##, count: ##}
                // console.log("averageByName function called");
                let rtn = this.reduce((acc, cur) => { return averageNumberByName(acc, cur, name); }, { average: 0, count: 0 });

                return rtn.count > 0 ? rtn.average : "";
            }
        }
    );
}


if (!Array.prototype.hasOwnProperty("findUnique")) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(
        Array.prototype,
        'findUnique',
        {
            value: function (name) {

                let array = this.map(elem => elem[name]);
                // console.log("Find unique in ", array);
                return array.filter((value, index, self) => self.indexOf(value) === index);
            }
        }
    );
}
