import dictionary from "../data/survey_dictionary.json";
import Cleaned from '../data/texas-airports-coordinates.json';
import '../helper/array.js';

//Get the information of the given question
//Example:
//getQuestionInfo("Q9")
//returns
// {
//     "id": "Q9",
//     "description": "Total Airport Acreage",
//     "label": "Airport Acreage",
//     "category": "Physical Infrastructure",
//     "subcategory": "",
//     "model": "Rates and Charges",
//     "isReport": "yes",
//     "isPopup": "yes",
//     "isSummary": "yes",
//     "isAverageable": "yes"
//   }
///////////////////////////////////////////////////////


export const getQuestionInfo = (q) => {
    return dictionary.hasItem("id", q)[0];
};

export const getQuestionInfoByLabel = (label) => {
    return dictionary.hasItem("label", label)[0];
}


// //Get the question list for the popup window
export const getPopupItems = () => {
    return dictionary.hasItem("isPopup", "yes");
};



// //Get the question list for the summary table
export const getSummaryItems = () => {
    return dictionary.hasItem("isSummary", "yes");
};

// Get the report items
export const getReportItems = () => {
    return dictionary.hasItem("isReport", "yes");
};


// //Get the question list for the rates and charges model
export const getRatesAndChargesItems = () => {
    return dictionary.hasItem("model", "Rates and Charges");
};

export const getAverageItems = () => {
    return dictionary.hasItem("isAverageable", "yes");
}

// //Get the question list for the intermodal model
export const getIntermodalItems = () => {
    return dictionary.hasItem("model", "Intermodal");
};

export const getAirportList = (filtered_survey) => {

    let testclean = Cleaned.filter((record) => {
        if (filtered_survey.hasItem("Airport", record.Airport).length > 0) {
            return true;
        } else {
            return false;
        };
    });
    return testclean;
}

export const getSummary = (item, data) => {
    let summary = "";
    const isSingle = (data.length === 1);
    const scale = item.scale ? item.scale : 1;

    if (item.isAverageable === "yes") {
        summary = isSingle ? data[0][item.id] : data.averageByName(item.id);
        if (summary === 0) summary = 0.00001; //make the criteria to be true instead of false
        summary = summary && (summary * scale);
        if (item.decimal !== null) summary = summary && item.prefix + " " + parseFloat(summary.toFixed(item.decimal)).toLocaleString() + " " + item.suffix;
        if (item.decimal === null) summary = summary && item.prefix + " " + parseFloat(summary.toFixed(2)).toLocaleString() + " " + item.suffix;


    }
    else if (item.isCountable !== "") {
        let keys = item.isCountable.split(",");
        let totals = data.length;
        let str_arr = keys.map((key) => { return data.filter((v) => v[item.id] !== null && key !== "" && v[item.id].includes(key)).length; });
        let str = keys[0];
        // if (item.id === "Q64_1" && keys.length > 0 && keys.join("").length > 0) {
        //     console.log("Current item is ", item);
        //     console.log("Current data is ", data);
        //     console.log("Filtered data is ", str_arr);
        //     console.log("Splitted array is ", keys);
        //     console.log("Concat str is ", str_arr.join("/" + totals + "\n"));
        // }
        if (keys.length > 1) str = str_arr.map((s, idx) => { return <p>{keys[idx] + ":" + s + "/" + totals}</p> });
        else str = str.concat(":" + str_arr[0] + "/" + totals);
        summary = isSingle ? data[0][item.id] : str;
    }
    return summary === "" ? "N/A" : summary;
}


const Helper = {
    getQuestionInfo,
    getPopupItems,
    getSummaryItems,
    getRatesAndChargesItems,
    getIntermodalItems,
    getQuestionInfoByLabel,
    getAverageItems,
    getAirportList,
    getSummary,
    getReportItems,
}
export default Helper;

// //Get columns for the 
