import { Typography, Button, Checkbox, FormControlLabel, TextField, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import "../helper/array.js";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
//import { SettingsInputAntennaTwoTone } from '@material-ui/icons';
function clone(obj) {
  if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
    return obj;

  if (obj instanceof Date)
    var temp = new obj.constructor(); //or new Date(obj);
  else
    var temp = obj.constructor();

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj['isActiveClone'] = null;
      temp[key] = clone(obj[key]);
      delete obj['isActiveClone'];
    }
  }
  return temp;
}

const FilterWrapper = (props) => {
  // const regions = ["Panhandle", "East Texas", "West Texas", "South Texas", "North Texas", "Central Texas", "Upper Gulf Coast"];
  const [regions, setRegions] = useState(null);
  const [previousFilters, setPreviousFilters] = useState(null);
  const [filters, setFilters] = useState(null);
  const [openDialog, SetOpenDialog] = useState({ "total area": false, "regions": false, "asset type": false, "development acres": false });
  const [message, setMessage] = useState(false);
  const updateMinInput = (e) => {
    //const mininputText = mininputRef.current.value
    let regionFilter = { ...filters };
    regionFilter.totalArea.min = e.target.value ? parseInt(e.target.value) : null;
    setFilters(regionFilter);

    //setuserminInput(mininputText);
  }

  const updateMaxInput = (e) => {
    //const maxinputText = maxinputRef.current.value
    //setusermaxInput(maxinputText);
    let regionFilter = { ...filters };
    regionFilter.totalArea.max = e.target.value ? parseInt(e.target.value) : null;
    setFilters(regionFilter);

  }

  const updateDevelopmentMinInput = (e) => {
    //const mininputText = mininputRef.current.value
    let regionFilter = { ...filters };
    regionFilter.developmentAcres.min = e.target.value ? parseInt(e.target.value) : null;
    setFilters(regionFilter);

    //setuserminInput(mininputText);
  }

  const updateDevelopmentMaxInput = (e) => {
    //const maxinputText = maxinputRef.current.value
    //setusermaxInput(maxinputText);
    let regionFilter = { ...filters };
    regionFilter.developmentAcres.max = e.target.value ? parseInt(e.target.value) : null;
    setFilters(regionFilter);

  }
  function handleCheck(e) {
    //e.target.checked = !e.target.checked;
    let regionFilter = clone(filters);
    regionFilter.Region[e.target.name] = !regionFilter.Region[e.target.name];
    setFilters(regionFilter);
  }

  function handleCheckAssetType(e) {
    let assetFilters = { ...filters };
    if (assetFilters.assetType.includes(e.target.name)) assetFilters.assetType = assetFilters.assetType.filter((type) => type != e.target.name);
    else assetFilters.assetType.push(e.target.name);

    setFilters(assetFilters);


  }


  function filterSubmission() {

    let filtered_survey = props.data;
    let regions = [];
    Object.keys(filters.Region).forEach((region) => {
      if (filters.Region[region]) regions.push(region);
    });
    if (regions) filtered_survey = filtered_survey.hasItem("Region", regions);

    filtered_survey = filtered_survey.withinRange("Q9", filters.totalArea.min, filters.totalArea.max);
    filtered_survey = filtered_survey.withinRange("Q77", filters.developmentAcres.min, filters.developmentAcres.max);
    if (filters.assetType) filtered_survey = filtered_survey.hasItem("Asset Category", filters.assetType);
    //props.setData(Survey.withinRange("Q9", filters.totalArea.min, filters.totalArea.max, filters.Region));
    if (filtered_survey.length > 0) {
      props.setData(filtered_survey);
      props.setFilters(filters);
      setMessage(filtered_survey.length + " records are retrieved.");
    }
    else {
      setMessage(filtered_survey.length + " records are retrieved, please select the criteria again.");
    }
  }

  const handleCloseTotalArea = (event, reason) => {


    let open = { ...openDialog };

    if (reason !== 'backdropClick') open["total area"] = !open["total area"]; //Prevent closing the dialog when click outside 

    SetOpenDialog(open);
  };

  const handleCloseRegions = (event, reason) => {
    let open = { ...openDialog };
    if (reason !== 'backdropClick') open.regions = !open.regions;
    SetOpenDialog(open);
    //SetOpenDialog({ "total area": !openDialog["total area"] });
  };

  const handleCloseAssetType = (event, reason) => {
    let open = { ...openDialog };
    if (reason !== 'backdropClick') open['asset type'] = !open['asset type'];
    SetOpenDialog(open);
  }


  const handleCloseDevelopmentAcres = (event, reason) => {
    let open = { ...openDialog };
    if (reason !== 'backdropClick') open['development acres'] = !open['development acres'];
    SetOpenDialog(open);
  }

  useEffect(() => {
    setFilters(props.filters);
    setPreviousFilters({
      Region:
      {
        "East Texas": true,
        "Panhandle": true,
        "West Texas": true,
        "North Texas": true,
        "South Texas": true,
        "Central Texas": true,
        "Upper Gulf Coast": true
      },
      totalArea: { min: null, max: null },
      developmentAcres: { min: null, max: null },
      assetType: ["Basic", "Commercial", "Local", "National", "Regional", "State", "Unclassified"]
    });
    setRegions(Object.keys(props.filters.Region));
  }, []);

  useEffect(() => {
    setMessage(props.data.length + " records are retrieved.");

  }, []);
  return regions && (<div>



    <Dialog open={openDialog["regions"]} onClose={handleCloseRegions}>
      <DialogTitle>Please check the regions for airports you want to select</DialogTitle>
      <DialogContent>
        {regions.map((Region, idx) => {
          return (
            <FormControlLabel label={Region} control={<Checkbox color="secondary" name={Region} checked={filters.Region[Region]} onClick={handleCheck} />}></FormControlLabel>

          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {

          setFilters(clone(previousFilters));

          handleCloseRegions();
        }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {
          setFilters(clone(filters));

          setPreviousFilters(clone(filters));
          handleCloseRegions();
          filterSubmission();
        }} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={openDialog["asset type"]} onClose={handleCloseAssetType}>
      <DialogTitle>Please check the asset category for airports you want to select</DialogTitle>
      <DialogContent>
        {["Basic", "Commercial", "Local", "National", "Regional", "State", "Unclassified"].map((type, idx) => {
          return (
            <FormControlLabel label={type} control={<Checkbox color="secondary" name={type} checked={filters.assetType.includes(type)} onClick={handleCheckAssetType} />}></FormControlLabel>

          );
        })}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setFilters(clone(previousFilters));
          handleCloseAssetType();
        }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {
          setFilters(clone(filters));

          setPreviousFilters(clone(filters));
          handleCloseAssetType();
          filterSubmission();
        }} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={openDialog["total area"]} onClose={handleCloseTotalArea}>
      <DialogTitle>Please enter the range of total area for airports you want to select</DialogTitle>
      <DialogContent>
        <TextField id="minimum" label="Min" value={filters.totalArea.min} variant="outlined" onChange={updateMinInput}>{filters.totalArea.min}</TextField>
        <TextField id="maximum" label="Max" value={filters.totalArea.max} variant="outlined" onChange={updateMaxInput}>{filters.totalArea.max}</TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setFilters(clone(previousFilters));
          handleCloseTotalArea();
          // filterSubmission();
        }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {
          setFilters(clone(filters));
          setPreviousFilters(clone(filters));
          handleCloseTotalArea();
          filterSubmission();
        }} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={openDialog["development acres"]} onClose={handleCloseDevelopmentAcres}>
      <DialogTitle>Please enter the range of total area for airports you want to select</DialogTitle>
      <DialogContent>
        <TextField id="minimum" label="Min" value={filters.developmentAcres.min} variant="outlined" onChange={updateDevelopmentMinInput}></TextField>
        <TextField id="maximum" label="Max" value={filters.developmentAcres.max} variant="outlined" onChange={updateDevelopmentMaxInput}></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setFilters(clone(previousFilters));
          handleCloseDevelopmentAcres();
          //filterSubmission();
        }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {
          setFilters(clone(filters));
          setPreviousFilters(clone(filters));
          handleCloseDevelopmentAcres();
          filterSubmission();
        }} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <Grid item container xs direction="column" spacing={0} style={{ paddingTop: "10%" }}>
      <Grid item xs>
        <Button id="select-regions" onClick={handleCloseRegions}>Regions</Button>
      </Grid>
      <Grid item xs>
        <Button id="select-asset-type" onClick={handleCloseAssetType}>Asset Categories</Button>
      </Grid>
      <Grid item xs>
        <Button id="fill-range-total-area" onClick={handleCloseTotalArea}>Total Area Range</Button>
      </Grid>
      <Grid item xs>
        <Button id="fill-range-development-acres" onClick={handleCloseDevelopmentAcres}>Developable Acres Range</Button>
      </Grid>
      <Grid item xs>
        <Typography style={{ fontStyle: 'italic' }}>{message}</Typography>
      </Grid>
    </Grid>

  </div>)

}

export default FilterWrapper;