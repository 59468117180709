import { React } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { TextField, Grid, makeStyles, Typography } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from '@material-ui/core/Paper';



const SecondSelectionTable = (props) => {
	//const [props.selectedAirport, setComparedAirport] = useState(false);
	const useStyles = makeStyles((theme) => ({

		inputRoot: {
			color: 'black',
			//   borderColor: 'black'
		},
		headerName: {
			width: '150px',
		},
		table: {
			height: '100%',
			paddingLeft: '8px',
			paddingRight: '8px',
			width: '98%'
		},
		dropdown: {
			paddingLeft: '8px',
		}
	}));


	const classes = useStyles();

	const handleChange = (e, newValue) => {
		if (newValue !== null && typeof (newValue) !== "undefined") {
			props.setComparedAirport(newValue);

		}
	};

	// useEffect(() => {
	// 	setComparedAirport(props.props.selectedAirport);
	// }, []);
	return (
		<Paper style={{ height: "100%" }}>

			<Grid container direction="column" spacing={2}>
				<Grid container item direction="row">
					<Grid item xs>	{props.selectedAirport && <Autocomplete
						className={classes.dropdown}
						onChange={handleChange}
						/// Options have to be sorted by the item for grouping first to group them correctly
						value={props.comparedAirport || null}
						id="search-compared"
						size="small"
						disabled={props.selectedAirport ? false : true}
						// classes={{input:classes.inputRoot}}
						options={props.allAirports.sort((a, b) => -b.Region.localeCompare(a.Region))}
						groupBy={(option) => option.Region}
						getOptionLabel={(option) => option.Airport}
						style={{ width: 300, height: 40 }}
						renderInput={(params) => <TextField {...params} label="Select a Airport to Compare" variant="outlined" />}
					/>}</Grid>
				</Grid>


				<Grid item xs
				>
					{props.selectedAirport && <TableContainer className={classes.table}>
						<Table stickyHeader size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell className={classes.headerName}>Name</TableCell>
									<TableCell>{props.selectedAirport.Airport}</TableCell>
									{props.comparedAirport && <TableCell>{props.comparedAirport.Airport}</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>Region</TableCell>
									<TableCell>{props.selectedAirport.Region}</TableCell>
									{props.comparedAirport && <TableCell>{props.comparedAirport.Region}</TableCell>}
								</TableRow>

								<TableRow>
									<TableCell>Asset Category</TableCell>
									<TableCell>{props.selectedAirport["Asset Category"]}</TableCell>
									{props.comparedAirport && <TableCell>{props.comparedAirport["Asset Category"]}</TableCell>}
								</TableRow>
								<TableRow>
									<TableCell>Reliever</TableCell>
									<TableCell>{props.selectedAirport.Reliever}</TableCell>
									{props.comparedAirport && <TableCell>{props.comparedAirport.Reliever}</TableCell>}
								</TableRow>
								<TableRow>
									<TableCell>Total Acres</TableCell>
									<TableCell>{props.selectedAirport.Q9.toLocaleString()}</TableCell>
									{props.comparedAirport && <TableCell>{props.comparedAirport.Q9.toLocaleString()}</TableCell>}
								</TableRow>

								<TableRow>
									<TableCell>Developable Acres</TableCell>
									<TableCell>{props.selectedAirport.Q77.toLocaleString()}</TableCell>
									{props.comparedAirport && <TableCell>{props.comparedAirport.Q77.toLocaleString()}</TableCell>}
								</TableRow>


							</TableBody>
						</Table>
					</TableContainer>}
					{
						!props.selectedAirport && <Typography>Please select your airport from the dropdown menu or the map.</Typography>
					}

				</Grid>
			</Grid>



		</Paper>
	)
}
export default SecondSelectionTable

