import { Fragment, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Helper from '../helper/helper';
import '../helper/array'
import Description from '../data/category_description.json'
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	wrapper: {
		height: '800px'
	},
	tableRow: {
		"&>:nth-child(n+5)": {
			minWidth: 130,

		},
	},
}));

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
});

const IntermodalTable = (props) => {

	const [isCollapsed, setCollapsed] = useState(true);
	const handleClick = (e) => {
		setCollapsed(!isCollapsed);
	};

	const items = Helper.getIntermodalItems();
	const categories = items.findUnique("category");
	const classes = useStyles();
	const createRowsBy = (data, category, group) => {
		//const { data, category, group } = props;

		let rows = Helper.getIntermodalItems()
			.hasItem(category.name, category.value)
			.map((item) => {
				return group.map((e) => item[e]).concat([Helper.getSummary(item, props.data)])
					.concat(data.map((e) => e[item.id] && (item.prefix + e[item.id] + " " + item.suffix)));
			});
		return rows;
	};

	const Row = (props) => {
		const { rows, category, level, children, maxlevel } = props;
		const [open, setOpen] = useState(false);
		const levels = level ? Array.from(Array(level).keys()) : new Array(0);//Requires ES6 polyfill to run
		const levels2 = level ? Array.from(Array(level + 1).keys()) : new Array(0);//Requires ES6 polyfill to run
		const levelsmax = maxlevel ? Array.from(Array(maxlevel - level).keys()) : new Array(0);//Requires ES6 polyfill to run
		const classes = useRowStyles();

		return (<Fragment>
			<TableRow key={category}>
				{levels.map((e) => { return <TableCell colSpan={1} />; })}
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>

				<TableCell>{category}</TableCell>
				{levelsmax.map((e) => <TableCell colSpan={1} />)}
				<TableCell colSpan={rows[0].length - 1 + level}>
					<Typography variant="body2" color="textSecondary" component="p">
						{Description.hasItem("name", category)[0] ? Description.hasItem("name", category)[0].description : ""}
					</Typography>

				</TableCell>

			</TableRow>

			{children ? (open && children) : (open && rows.map((row, id) => (

				<TableRow key={category + level}>
					{levels2.map((e) => <TableCell colSpan={1} />)}
					{levelsmax.map((e) => <TableCell colSpan={1} />)}
					<TableCell></TableCell>
					{row.map((e) => <TableCell colSpan={1}>{e}</TableCell>)}
				</TableRow>

			)))}

		</Fragment>);
	};

	const RowSet = (props) => {
		//data: The set of data to show
		//categories: Categories array. Each element indicate the category name to collapse.
		//cols: ids in the data record indicate the category
		//children: 
		const { data, categories, col, group, level, maxlevel } = props;
		const isEmpty = (arr, cat) => {
			if (arr.hasItem("category", cat).findUnique("subcategory").length > 1) return false;
			else return true;
		}
		const classes = useRowStyles();
		return (<Fragment>

			{categories.map((cat) => <Row rows={createRowsBy(data, { name: col, value: cat }, group)} category={cat} level={level} maxlevel={1}
				children={maxlevel - level === 0 || isEmpty(Helper.getIntermodalItems(), cat) ? null : <RowSet categories={Helper.getIntermodalItems().hasItem(col, cat).findUnique("subcategory")} data={data} col={"subcategory"} group={["label"]} level={level + 1} />} />)}
		</Fragment>

		);
	};



	return (
		<Paper style={{ height: "99.8%", overflow: 'auto' }}>

			{/* <Splitted style={{ height: "700px" }}></Splitted>	 */}


			<TableContainer style={{ height: "100%" }}>
				<Table stickyHeader size="small" aria-label="a dense table">
					<TableHead>
						<TableRow className={classes.tableRow}>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Summary</TableCell>


							{props.data.map((elem) => (
								<TableCell>
									{elem.Airport}
								</TableCell>
							))}


						</TableRow>
					</TableHead>

					<TableBody>
						<RowSet categories={categories} data={props.data} col={"category"} group={["subcategory", "label"]} level={0} maxlevel={1}></RowSet>
						{/* {categories.map((cat) =>
							<Row rows={createRowsBy(props.data, { name: "category", value: cat }, ["subcategory", "label"])} category={cat} level={0}>

							</Row>
						)} */}


					</TableBody>
				</Table>
			</TableContainer>
			{/* <Test></Test>  */}

		</Paper>
	);
}
export default IntermodalTable;

