//import React from "react";
import { useState, useEffect } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MapForIntermodal from '../view/MapForIntermodal';
import IntermodalTable from "../view/IntermodalTable";
import FilterWrapper from "../view/FilterWrapper";
import { List, ListItem, ListItemText } from "@material-ui/core";
import yellowIcon from "../img/flight_yellow_24dp.svg"
import blueIcon from "../img/flight_blue_24dp.svg"
import greyIcon from "../img/flight_grey_24dp.svg"
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingLeft: "1%",
            paddingRight: "1%",
        },
        paper: {
            padding: theme.spacing(2),
            height: "800px",
            textAlign: "center",
            color: theme.palette.text.secondary,
            boxSizing: "border-box"
        },
        gridItem: {
            boxSizing: "border-box",
            padding: theme.spacing(1)
        }

    })
);

const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    alignItems: 'center',
};
const itemStyle = {
    height: "100%"
};

const IntermodalPanel = (props) => {
    const classes = useStyles();
    const [data, setData] = useState(false);
    const [filters, setFilters] = useState(false);
    useEffect(() => {
        if (props.data) setData(props.data);
        setFilters({
            Region:
            {
                "East Texas": true,
                "Panhandle": true,
                "West Texas": true,
                "North Texas": true,
                "South Texas": true,
                "Central Texas": true,
                "Upper Gulf Coast": true
            },
            totalArea: { min: null, max: null },
            developmentAcres: { min: null, max: null },
            assetType: ["Basic", "Commercial", "Local", "National", "Regional", "State", "Unclassified"]
        });
    }, []);

    return (
        <Paper className={classes.root} >
            {data && <Grid container spacing={3} style={{ height: "780px" }}>

                <Grid item container lg={4} md={6} xs={12} className={classes.gridItem} style={{ height: "100%" }}>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={6}
                        //className={`${classes.masonryItemFirst}`}
                        style={{ height: "50%" }}
                    >
                        <MapForIntermodal selectedAirport={props.selectedAirport} setSelectedAirport={props.setSelectedAirport} data={data} />
                    </Grid>
                    <Grid style={{ height: "10%" }}>
                        <List dense style={flexContainer}>
                            <ListItem style={itemStyle}>
                                <img style={itemStyle} alt="" src={yellowIcon} />
                                <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Currently Selected Airport</Typography>} />
                            </ListItem>
                            <ListItem style={itemStyle}>
                                <img alt="" src={blueIcon + "#svgView(viewBox(7,-12,24,24))"} />
                                <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport with Information</Typography>} />
                            </ListItem>
                            <ListItem style={itemStyle}>
                                <img alt="" src={greyIcon + "#svgView(viewBox(7,-12,24,24))"} />
                                <ListItemText primary={<Typography style={{ fontSize: "x-small" }}>Airport without Information</Typography>} />
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={6}
                        // className={`${classes.masonryItemSecond}`}
                        style={{ height: "40%" }}
                    >
                        <Paper style={{ height: "100%" }}>
                            <FilterWrapper filters={filters} data={props.data} setData={setData} setFilters={setFilters}></FilterWrapper>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item lg={8} md={6} xs={12} className={`${classes.gridItem}`} style={{ height: "100%" }}>

                    <IntermodalTable data={data.removeEmptyItems(["X", "Y", "Region", "County", "Airport", "Asset Category", "Reliever"])} />

                </Grid>

            </Grid>}

        </Paper>

    );
};
export default IntermodalPanel;
